export const data = {
  user: {
    userId: "1",
    username: "pinballboy",
    avatar: "",
    tourNumber: 12,
    top3AllTimeGames: ["Rocket League", "Crosscode", "Minecraft"],
    top3IndieGames: ["Oxenfree", "Kabounce", "SuperhotVR"],
    favoriteGenres: ["Pinball", "Action", "RPG"],
    favoriteSettings: ["Fantasy", "Atmospheric", "Story"],
  },
  games: [
    {
      gameId: 1,
      gameName: "Hotline Miami",
      score: 97,
      ratings: 40568,
      gameHeader: "hotline-miami.jpg",
      developer: "Dennaton Games",
      shortDescription: "Hotline Miami is a high-octane action game overflowing with raw brutality, hard-boiled gunplay and skull crushing close combat.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/219150/Hotline_Miami/",
      },
      reasonsToPlay: ["Retro-Style Graphics like Crosscode", "Dodging Bullets like Superhot"],
      awards: [""],
    },
    {
      gameId: 2,
      gameName: "Yoku's Island Express",
      score: 96,
      ratings: 530,
      gameHeader: "yokus-island-express.jpg",
      developer: "Villa Gorilla & Team17",
      shortDescription: "Meet Yoku, the pint-sized postman protagonist of Yoku's Island Express! Use a unique blend of pinball mechanics, platforming and open world exploration to unlock the secrets of Mokumana Island, help the locals, awaken ancient deities and much more in this amazing new tropical adventure!",
      gameChip: ["Worms Developer"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/334940/Yokus_Island_Express/",
        nintendo: "https://www.nintendo.com/games/detail/yokus-island-express-switch/",
      },
      reasonsToPlay: ["More Pinball", "Similar to Kabounce"],
      awards: [""],
    },
    {
      gameId: 3,
      gameName: "Night in the Woods",
      score: 94,
      ratings: 6723,
      gameHeader: "night-in-the-woods.jpg",
      developer: "Infinite Fall",
      shortDescription: "NIGHT IN THE WOODS is an adventure game focused on exploration, story, and character, featuring dozens of characters to meet and lots to do across a lush, vibrant world.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/481510/Night_in_the_Woods/",
      },
      reasonsToPlay: ["Similar to Oxenfree", "Story Rich", "Atmospheric"],
      awards: [""],
    },
    {
      gameId: 4,
      gameName: "Hollow Knight",
      score: 95,
      ratings: 45003,
      gameHeader: "hollow-knight.jpg",
      developer: "Team Cherry",
      shortDescription: "Forge your own path in Hollow Knight! An epic action adventure through a vast ruined kingdom of insects and heroes. Explore twisting caverns, battle tainted creatures and befriend bizarre bugs, all in a classic, hand-drawn 2D style. ",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/367520/Hollow_Knight/",
      },
      reasonsToPlay: ["Atmospheric", "Story", "Platformer"],
      awards: [""],
    },
    {
      gameId: 5,
      gameName: "Planet Centauri",
      score: 81,
      ratings: 1075,
      gameHeader: "planet-centauri.jpg",
      developer: "Permadeath",
      shortDescription: "Embark on your greatest adventure to Planet Centauri! Explore hidden dungeons, capture and tame monsters, build and protect a NPC community, craft your own weapons and create your own magic spells! Develop and raise the Planet Centauri to the heavens as the cradle of a new civilization!",
      gameChip: ["Early Access"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/385380/Planet_Centauri/",
      },
      reasonsToPlay: ["Sandbox like Minecraft", "Retro-Style Graphics"],
      awards: [""],
    },

    {
      gameId: 6,
      gameName: "The Vanishing of Ethan Carter",
      score: 88,
      ratings: 6017,
      gameHeader: "the-vanishing-of-ethan-carter.jpg",
      developer: "The Astronauts",
      shortDescription: "The Vanishing of Ethan Carter is a first-person story-driven mystery. Purchase this game to get both the original and the Unreal Engine 4 remaster called The Vanishing of Ethan Carter Redux.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/258520/The_Vanishing_of_Ethan_Carter/",
      },
      reasonsToPlay: ["Atmospheric", "Story", "Similar to Oxenfree"],
      awards: [""],
    },
    {
      gameId: 7,
      gameName: "Trackmania United Forever Star Edition",
      score: 94,
      ratings: 897,
      gameHeader: "trackmania-united-star-edition.jpg",
      developer: "Nadeo",
      shortDescription: "TrackMania is the most entertaining car racing game ever. Millions of players play it in single or multiplayer modes. TrackMania United Forever is the ultimate TrackMania edition thanks to the numerous additions and innovations it has to offer.",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/7200/Trackmania_United_Forever_Star_Edition/",
      },
      reasonsToPlay: ["Racing", "Similar to Rocket League", "Similar to Kabounce"],
      awards: [""],
    },
  ],
  stats: {
    number: [3, 3, 1],
    category: ["Retro-Style Games", "Story-driven Games", "Pinball Game"],
    reason: ["because you like Oxenfree, Crosscode, and Minecraft", "because you like Story, Oxenfree and Crosscode", "because you like Action, Rocket League, and Kabounce"],
  },
};